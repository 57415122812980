import { settings } from "@/settings";
import { ComboBox, Decision, Offer, Picklist, Task, TaskExecuteAction, TaskState, } from "@/types";
import { deepClone, getOperator, globalConfig, headers, Masks, UON, } from "@/utils";
import axios from "axios";
import i18n from "@/i18n";
import { executeTask } from "@/store/services/task/workflow";
import { useApi } from "@/requests/useApi";
import moment from "moment";
import { api } from "@/auth/api";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;

const odm_decision = `odm-decision`
const odm_user = `odm-user`
const baseUrl = `${settings.api_url}/${odm_decision}/api/1/${odm_decision}`
const decisionReservesObjectType = "odm.decision.elementreserve.decisionreserve"

export async function executeDecisionAction(
  taskState: TaskState,
  userId: string,
  offer: Offer,
  task: Task
) {
  const taskExecuteAction: TaskExecuteAction = {
    taskId: task.taskId || "",
    variables: {},
  };
  try {
    if (taskState.decisionModule.acceptDecision?.decision.value) {
      const acceptDecision = taskState.decisionModule.acceptDecision;
      taskExecuteAction.variables.decision = acceptDecision;
      acceptDecision.reference = `ACCEPT-${offer.resourceUid}`
      taskExecuteAction.variables.decision.resourceUid = taskState.selectedTask.resourceUid
      await takeDecision(acceptDecision, offer.resourceUid || "");
      return executeTask(taskExecuteAction);
    }
    if (taskState.decisionModule.decision?.decision.value) {
      const decision = taskState.decisionModule.decision;
      taskExecuteAction.variables.decision = decision;
      decision.reference = `DECISION-${offer.resourceUid}`
      taskExecuteAction.variables.decision.resourceUid = taskState.selectedTask.resourceUid
      if(taskState.decisionModule.decision.decision.value === 'ACCEPTED_RESERVES' && taskState.offer?.resourceUid){
        let salesPerson = await getEmailSalesPerson(taskState.offer.resourceUid)
        if(salesPerson.data.length > 0){
          taskExecuteAction.variables.emailRecipient = {type: 'string', value: salesPerson.data[0].email}
          taskExecuteAction.variables.nameRecipient = {type: 'string', value: salesPerson.data[0].first_name + ' ' + salesPerson.data[0].last_name}
        }
      }
      await takeDecision(decision, offer.resourceUid || "");
      return executeTask(taskExecuteAction);
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}



function setDecision(decisionCloned: Decision, offerId?: string) {
  if (
    decisionCloned.decisionReasons &&
    decisionCloned.decisionReasons.length != 0
  ) {
    decisionCloned.decisionReasons.forEach((reason: any) => {
      if (typeof reason !== "string") {
        reason.resourceUid = `${reason.value}_${offerId}`;
        reason.reason = {
          resourceUid: reason.value
        }
        reason.daaq = "/";
        delete reason.value;
        delete reason.label;
      }
    });
  }
  if (
    decisionCloned.decisionReserves &&
    decisionCloned.decisionReserves.length != 0
  ) {
    decisionCloned.decisionReserves.forEach((reserve: any) => {

      reserve.objectType = decisionReservesObjectType,
        reserve.resourceUid = `${reserve.value}_${offerId}`;
      reserve.daaq = "/";
      reserve.comment = reserve.config.comment;
      reserve.reserve = {
        resourceUid: reserve.value
      }
      delete reserve.value;
      delete reserve.label;
      delete reserve.config
    });
  }
  decisionCloned = {
    ...decisionCloned,
    decisionDate: addHours(decisionCloned.decisionDate),
    decisionEntity: {
      resourceUid: offerId,
      objectType: UON.ObjectType.offer,
      systemUid: UON.SystemUid.odmOffer,
    },
    status: {
      resourceUid: decisionCloned.decision.value,
    },
    decisionMakerId: {
      resourceUid: store.state.authModule?.user.userId,
      objectType: "odm.user.userinfo",
      systemUid: "odm-user",
    },
    daaq: "/",
  };
  delete decisionCloned.decision;
  return decisionCloned;
}

export async function takeDecision(decision: Decision, offerId?: string) {
  const clone = setDecision(deepClone(decision), offerId);
  const { error } = await useApi(
    UON.SystemUid.odmDecision,
    "decisionprocess"
  ).upsert(clone);
  if (error) {
    throw error;
  }
}

const addHours = (date: string) => {
  const formattedDate = new Date(moment(date).format(Masks.dateMask));
  return moment(formattedDate.setTime(new Date().getTime())).format(
    Masks.timestampMask
  );
};

export function getListDecision(filter: any): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "task-decision-status",
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators = [];
    if (filter && filter.id != null) {
      operators.push(
        getOperator(
          "decision_decision_status",
          "id",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.id),
          "id"
        )
      );
    }
    if (filter && filter.list != null) {
      operators.push(
        getOperator("decision_decision_status", "id", "IN", filter.list, "id")
      );
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 50;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };

        resolve(response);
      })
      .catch((err) => {

        resolve({
          systemUid: "odm_decision",
          items: [
            {
              value: "accepted",
              label: i18n.global.t("decision.status.Customer_Accepted"),
            },
            {
              value: "refused",
              label: i18n.global.t("decision.status.Customer_Refused"),
            },
          ],
        });
      });
  });
}
export async function getDecision(decisionId: string) {

  return new Promise<any>(async (resolve, reject) => {
    await api().get(`${baseUrl}/decisionprocess/${decisionId}/`)
      .then(res => resolve(res.data)).catch(e => reject(e))
  })

}



export async function executeDecisionUpdate() {
  const response =await  getConnectedUser()
  const opinionMakerId = {
    objectType: "odm.user.userinfo",
    systemUid: "odm-user",
    resourceUid: response.data.resourceUid
}
  const ListDecisions = store.state.taskModule.decisions || []
  let decisionToUpdate
  for (const decision of ListDecisions) {
    if (decision.opinions.length > 0) {
      decisionToUpdate = decision
    }
  }
  const clone = buildDecision(deepClone(decisionToUpdate),opinionMakerId)
  const { error } = await useApi(
    UON.SystemUid.odmDecision,
    "decisionprocess"
  ).upsert(clone);
  if (error) {
    throw error;
  }
}


export function buildDecision(decisionToUpdate: any ,opinionMakerId: any) {
  decisionToUpdate.associatedAnalysisProcess.forEach((analysisProcess: any) => {
    delete analysisProcess.analysisDate
    delete analysisProcess.analysisMaker
    delete analysisProcess.analysisProcessReference
    delete analysisProcess.analysisProcessResourceUid
    analysisProcess.reserves = analysisProcess.reserves.filter((reserve: any) => !reserve.objectType.includes('opinionreserve'))
  })
  decisionToUpdate.opinions
    .forEach((opinion
      : any) => {
      delete opinion.opinionMaker
      delete opinion.opinionDate
      delete opinion.opinionAnalysisProcessReference
      delete opinion.opinionAnalysisProcessRessourceUid

      opinion.reserves.filter((reserve: any) => reserve.objectType.includes('analysisreserve')).map((reserve: any) => {
        reserve.objectType = "odm.decision.elementreserve.opinionreserve"
        delete reserve.resourceUid
      })

    })
   if (decisionToUpdate.opinions.length >0) {
      if (decisionToUpdate.opinions.length > 1) {
        decisionToUpdate.opinions[decisionToUpdate.opinions.length - 2].reserves = decisionToUpdate.opinions[decisionToUpdate.opinions.length - 2].reserves.filter((reserve: any) => reserve.resourceUid !== undefined || reserve.resourceUid !== '')
      }
      decisionToUpdate.opinions[decisionToUpdate.opinions.length - 1].reserves = decisionToUpdate.opinions[decisionToUpdate.opinions.length - 1].reserves.filter((reserve: any) => reserve.resourceUid === undefined || reserve.resourceUid === '')
      decisionToUpdate.opinions[decisionToUpdate.opinions.length - 1].opinionMakerId = opinionMakerId
    }

  delete decisionToUpdate.decisionMakerProfil
  delete decisionToUpdate.decisionMaker

  decisionToUpdate = {
    ...decisionToUpdate,
    decisionDate: addHours(decisionToUpdate.decisionDate),
  };

  return decisionToUpdate;
}


export async function executeDecisionOpinionAction(
  task: Task
) {
  const taskExecuteAction: TaskExecuteAction = {
    taskId: task.taskId || ""
  };
  try {
    await executeDecisionUpdate();
    return executeTask(taskExecuteAction);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export  function buildEnterDecision(decisionToUpdate: any, decisionMakerId: any) {
  decisionToUpdate.decisionMakerId=decisionMakerId
  decisionToUpdate.associatedAnalysisProcess.forEach((analysisProcess: any) => {
    delete analysisProcess.analysisDate
    delete analysisProcess.analysisMaker
    delete analysisProcess.analysisProcessReference
    delete analysisProcess.analysisProcessResourceUid
  })
  decisionToUpdate.opinions = decisionToUpdate.opinions.filter((opinion: any) =>  opinion.resourceUid !== undefined || opinion.resourceUid !== '')
  decisionToUpdate.opinions
    .forEach((opinion
      : any) => {
      delete opinion.opinionMaker
      delete opinion.opinionDate
      delete opinion.opinionAnalysisProcessReference
      delete opinion.opinionAnalysisProcessRessourceUid


    })
  if (decisionToUpdate.opinions.length > 1) {

    decisionToUpdate.opinions[decisionToUpdate.opinions.length - 2].reserves = decisionToUpdate.opinions[decisionToUpdate.opinions.length - 2].reserves.filter((reserve: any) => reserve.resourceUid !== undefined || reserve.resourceUid !== '')
    decisionToUpdate.opinions[decisionToUpdate.opinions.length - 1].reserves = decisionToUpdate.opinions[decisionToUpdate.opinions.length - 1].reserves.filter((reserve: any) => reserve.resourceUid !== undefined || reserve.resourceUid !== '')

  }

  decisionToUpdate.decisionReserves.filter((reserve: any) => reserve.objectType.includes('opinionreserve')).map((reserve: any) => {
    reserve.objectType = "odm.decision.elementreserve.decisionreserve"
    delete reserve.resourceUid
  })
  delete decisionToUpdate.decisionMakerProfil
  delete decisionToUpdate.decisionMaker

  decisionToUpdate = {
    ...decisionToUpdate,
    decisionDate: addHours(decisionToUpdate.decisionDate),
  };

  return decisionToUpdate;
}

export async function executeEnterDecisionUpdate() {
  const response =await  getConnectedUser()
  const decisionMakerId = {
    objectType: "odm.user.userinfo",
    systemUid: "odm-user",
    resourceUid: response.data.resourceUid
}
  const decisionToUpdate = store.state.taskModule.decisionModule.selectedDecision
  const clone = buildEnterDecision(deepClone(decisionToUpdate),decisionMakerId)
  const { error } = await useApi(
    UON.SystemUid.odmDecision,
    "decisionprocess"
  ).upsert(clone);
  if (error) {
    throw error;
  }
}
export async function executeEnterDecisionAction(
  task: Task
) {
  const taskExecuteAction: TaskExecuteAction = {
    taskId: task.taskId || "",
    variables: {},
  };
  try {
    await executeEnterDecisionUpdate();
    return executeTask(taskExecuteAction);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function getDecisionReason(filter: any): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;

      const qc_header: any = {
          qc: {
              queryId: 'decision-picklist-reason',
          }
      }
      qc_header.qc.parameters = {
          ...filter
      }
      const operators = []
      if (filter && filter.decision_status_code != null) {
          operators.push(getOperator("decision_reason", "decision_status_code", "EQUAL", `'${filter.decision_status_code}'`, "decision_status_code"))
      }

      if (operators.length > 0) {
          qc_header.qc.otherFilters = {
              expressions: [
                  {
                      and: {
                          operators
                      }
                  }
              ]
          }
          qc_header.qc.otherOrders = null
          qc_header.qc.offset = 0
          qc_header.qc.limit = 50
      }

      const _headers = headers({
          qc: JSON.stringify(qc_header.qc)
      })

      axios.get(url, {
          headers: _headers
      }).then(res => {

          const response = {
              systemUid: 'odm-decision',
              items: res.data.map((resItem: any) => {
                  return {
                      id: resItem.id,
                      label: i18n.global.t("task.reasons." + resItem.id),
                      config: {
                        decision_status_code: filter.decision_status_code
                      }
                  }
              })

          }

          resolve(response)

      }).catch(err => {


          resolve({
              systemUid: '',
              items: [
                  {
                      id: 'odm-party',
                      label: 'Crée2'
                  },

              ]
          })
      });

    })}
export async function getEmailSalesPerson(offerId: string) {
  return new Promise<any>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: 'offer-sales-person-email',
        offset: 0,
        limit: 1000000,
      }
    }

    qc_header.qc.parameters = {
      offer_id: offerId
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios.get(settings.api_query_url, {
      headers: _headers
    }).then(res => {
      if(!res.data.length){
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          type: 'warning',
          message: 'Was not possible get email sales person',
        })
      }
      resolve(res)

    }).catch(err => {
      reject(err)
    })
  })
}

export  function getConnectedUser() {
  return api().get(`${settings.api_url}/odm-user/api/1/odm-user/whoami/`);
}