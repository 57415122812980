import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

import i18n from '../i18n'
import Home from "@/components/Home.vue";
import { tokenIsValid, checkUserByRole } from "@/utils";
import { HomePageRoles } from "@/types";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/components/auth/Registration.vue')
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/components/auth/ForgotPassword.vue')
    },
    {
        path: '/demand/list',
        name: 'demand-list',
        component: () => import('@/views/demand/DemandViewList.vue')
    },
    {
        path: '/demand/edit/:id?',
        name: 'demand-edit',
        component: () => import('@/views/demand/DemandViewEdit.vue')
    },
    {
        path: '/order/edit/:id?',
        name: 'order-edit',
        component: () => import('@/views/order/OrderViewEdit.vue')
    },
    {
        path: '/mo/demand/detail/:id?',
        name: 'mo-demand-detail',
        component: () => import('@/views/middle-office/MiddleOfficeView.vue')
    },
    {
        path: "/order",
        name: "order",
        component: () => import("@/views/order/OrderViewList.vue")
    },
    {
        path: '/third/list',
        name: 'third-list',
        component: () => import('@/views/party/ThirdViewList.vue')
    },
    {
        path: '/third/edit',
        name: 'third-edit',
        component: () => import('@/views/party/ThirdViewEdit.vue')
    },
    {
        path: '/task/list',
        name: 'task-list',
        component: () => import('@/views/tasks/TaskViewList.vue')
    },
    {
        path: '/task/detail',
        name: 'TaskDetail',
        component: () => import('@/views/tasks/TaskView.vue')
    },
    {
        path: '/scale/list',
        name: 'scale-list',
        component: () => import('@/views/configuration/scales/ScaleViewList.vue')
    },
    {
        path: '/scale/edit',
        name: 'scale-edit',
        component: () => import('@/views/configuration/scales/ScaleViewDetails.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/user-preference/ProfileView.vue')
    }, {
        path: '/garanty/list',
        name: 'garanty-list',
        component: () => import('@/views/configuration/garanty/GuaranteeView.vue')
    },
    {
        path: '/good/list',
        name: 'asset-list',
        component: () => import('@/views/configuration/goods/GoodViewList.vue')
    },
    {
        path: '/screnConfiguration/list',
        name: 'screnConfiguration-list',
        component: () => import('@/views/configuration/screnConfiguration/ScrenConfigurationViewList.vue')
    },
    {
        path: '/userAdmin/list',
        name: 'user-list',
        component: () => import('@/views/configuration/users/UserViewList.vue')
    },
    {
        path: '/userAdmin/edit',
        name: 'user-edit',
        component: () => import('@/views/configuration/users/UserViewDetails.vue'),
    },
    {
        path: '/good/edit',
        name: 'good-edit',
        component: () => import('@/views/configuration/goods/GoodViewDetails.vue')
    }, {
        path: '/rule',
        name: 'rule',
        component: () => import('@/views/rule.vue')
    },
    {
        path: '/availability/list',
        name: 'availability-list',
        component: () => import('@/views/configuration/availabilities/AvailabilityViewList.vue')
    },
    {
        path: '/opinion/detail',
        name: 'OpinionDetail',
        component: () => import('@/views/tasks/OpinionView.vue')
    },
    {
        path: '/analysis/detail',
        name: 'AnalysisDetail',
        component: () => import('@/views/middle-office/AnalysisView.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        component: () => import('@/components/auth/ChangePassword.vue')
    },
    {
        path: '/role-menu',
        name: 'ScreenAuthority',
        component: () => import('@/views/configuration/role-menu/RoleMenuViewList.vue')
    },
    {
        path: '/deliverySchedule',
        name: 'deliverySchedule',
        component: () => import('@/views/deliverySchedule/DeliveryScheduleView.vue')
    },
    {
        path: '/superset/list',
        name: 'superset-list',
        component: () => import('@/views/configuration/superset/SupersetViewList.vue')
    },
    {
        path: '/dashboard',
        name: 'ScreenDashboard',
        component: () => import('@/views/configuration/dashboard/DashboardViewList.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.path.includes('task')) {
        store.dispatch('taskModule/setFilterByOfferReference', '')
    }

    if (to.path.includes("demand")) {
        store.dispatch("module", "demand");
    } else if (to.path.includes("third")) {
        store.dispatch("module", "third");
    } else if (to.path.includes("task")) {
        store.dispatch("module", "task");
    } else if (to.path.includes("good")) {
        store.dispatch("module", "good");
    } else if (to.path.includes("order")) {
        store.dispatch("module", "dossier");
    } else if (to.path.includes("deliverySchedule")) {
        store.dispatch("module", "deliverySchedule");
    } else {
        store.dispatch("module", "home");
    }

    if (['/', '/login', '/signup', '/forgotPassword'].includes(to.path) && tokenIsValid()) {
        const homePageRolesKeys = Object.keys(HomePageRoles);
        for (const key of homePageRolesKeys) {
            if (checkUserByRole([key])) {
                next('/' + (HomePageRoles as any)[key]);
                return;
            }
        }
    } else if (!['/','/login', '/signup', '/forgotPassword'].includes(to.path) && !tokenIsValid()) {
        next('/login');
    } else if (to.path === '/changePassword' && tokenIsValid()) {
        store.commit('authModule/setChangePassWordIn', false);
        next();
    } else {
        const lock = store.state.lockState;

        if (lock) {
            store.dispatch("showConfirmationDialog", {
                message: i18n.global.t('main.confirmationDialog.message'),
                target: to.fullPath
            });
            next(false);
        } else {
            next();
        }
    }
});


export default router
