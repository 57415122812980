import {
  AccessoryProductInfo,
  AuthUser,
  businessDataUtils,
  ComboBox,
  ComboBoxItem,
  ContactType,
  Controls,
  GuaranteeType,
  NoticeMessage,
  NoticeTemplate,
  Offer,
  Organization,
  paymentDatesOptions,
  Person,
  PicklistItem,
  ProductSelectionParameters,
  Proposal,
  QcHeader,
  RootState,
  TaskDefinition,
  TaskModule,
  UniformOdmName
} from '@/types';
import * as table_config_import from "@/utils/configuration/table-config";
import * as notify_config_import from "@/utils/configuration/notify-config";
import * as formatters_config_import from "@/utils/configuration/formatters-config";
import {
  formatAmoutswitchCurrency,
  formatDateLocale,
  getEqualValueStringOrBoolean,
  getLikeValue
} from "@/utils/configuration/formatters-config";

import * as input_rules from "@/utils/configuration/input-rules";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import axios from "axios";
import moment from "moment";
import i18n from "@/i18n";
import { RouteRecordName, Router } from "vue-router";
import router from "@/router";
import store from '@/store';
import Notify from 'quasar/src/plugins/Notify.js';import exportFile from 'quasar/src/utils/export-file.js';;
import { buildGenericInfo, buildOfferControls, buildPartyControls } from '@/store/services/controls/controlsMapper';
import { getProposalInfoScale } from '@/store/services/financing/financingMapper';
import { getAgeEndOfContract, getContractDuration, getFinancedValue, verifyPartyInsured } from '@/store/services/financing/financingService';

// CONSTANTS
const storageKeys = {
  simulationModule: 'simulationModule',
  thirdModule: 'thirdModule',
  demandModule: 'demandModule',
  authModule: 'authModule'
}
export const TAXDEFAULT = {
  val: 20
}
export const UON = {
  ObjectType: {
    frenchOrganization: 'odm.party.party.organization.frenchorganization',
    frenchPerson: 'odm.party.party.person.frenchperson',
    offer: 'odm.offer.offer',
    offerInitialization: 'odm.offer.offerinitializationcontext',
    associatedParty: 'odm.offer.associatedparty',
    maritalStatus: 'odm.party.maritalstatus',
    personTitle: 'odm.party.persontitle',
    offerAssociatedParty: 'odm.offer.offerassociatedparty',
    partyAddress: 'odm.party.partyaddress',
    address: 'odm.party.address',
    associatedPartyRole: 'odm.offer.associatedpartyrole',
    associatedGuaranteeUnderlyingAssets: 'odm.offer.associatedguaranteeunderlying.associatedguaranteeunderlyingassets',
    proposalGuarantee: {
      objectType: new Map<string, string>([
        [GuaranteeType.PERSONAL_GUARANTEE, 'odm.offer.proposalguarantee.proposalpersonalguarantee'],
        [GuaranteeType.BANK_GUARANTEE, 'odm.offer.proposalguarantee.proposalbankguarantee'],
        [GuaranteeType.MORTGAGE, 'odm.offer.proposalguarantee.proposalguaranteewithunderlying.proposalmortgageguarantee'],
        [GuaranteeType.PROPERTY_PLEDGING, 'odm.offer.proposalguarantee.proposalguaranteewithunderlying.proposalpropertypledgingguarantee'],
        [GuaranteeType.BUY_BACK, 'odm.offer.proposalguarantee.proposalguaranteewithunderlying.proposalbuybackguarantee']
      ])
    },
    fileManagement: {
      fileContent: "odm.filemngt.filecontent"
    }
  },
  SystemUid: {
    odmUser: 'odm-user',
    odmAuthentication: 'odm-authentication',
    odmParty: 'odm-party',
    odmOffer: 'odm-offer',
    odmFileManagement: 'odm-filemngt',
    odmProduct: 'odm-product',
    odmDecision: 'odm-decision',
    odmContactMechanism: 'odm-contactmechanism',
    odmAnalysis: 'odm-analysis',
    odmConfiguration: 'odm-configuration',
    odmOrder: 'odm-order',
    odmSupportingDocument: 'odm-supportingdocument',
    odmSimulation: 'odm-simulation',
    odmEsignbridge : 'odm-esignbridge',
    odmOrchestration: 'odm-orchestration',
  }
}

export const Paths = {
  team: 'team',
  person: 'person',
  actions: 'actions',
  simpleserviceproduct: 'simpleserviceproduct',
  assetinsuranceproduct: 'assetinsuranceproduct',
  maintenanceproduct: 'maintenanceproduct',
  partyinsuranceproduct: 'partyinsuranceproduct',
  user: 'user',
  frenchOrganization: 'frenchorganization',
  frenchPerson: 'frenchperson',
  offer: 'offer',
  associatedParty: 'associatedparty',
  offerAssociatedParty: 'offerassociatedparty',
  partyRelation: 'partyrelation',
  partyAddressPreference: 'partyaddresspreference',
  simpleFinancingOffer: 'simplefinancingoffer',
  financingProductPack: 'financingproductpack',
  simplePackageFeature: 'simplepackagefeature',
  productFeature: 'productfeature',
  financialProfile: 'financialprofile',
  proposalGuarantee: 'proposalguarantee',
  partyContactMechanism: {
    path: 'partycontactmechanism',
    contactMechanism: 'contactmechanism'
  },
  contactMechanism: {
    parentResourceUid: new Map<string, string>([
      [ContactType.EMAIL_CONTACT, 'emailcontact'],
      [ContactType.MESSENGER_CONTACT, 'messengercontact'],
      [ContactType.PHONE_CONTACT, 'phonecontact'],
      [ContactType.POSTAL_MAIL_CONTACT, 'postalmailcontact']
    ])
  },
  productAvailability: 'productavailability',
  configuration: {
    controls: 'business-control/validate',
    UiControls: 'uiManagement/screen'
  },
  order: {
    create: `order`,
    get: (resourceUid: string) => `order/${resourceUid}`,
    asset: {
      create: (parentResourceUid: string) => `orderitem/${parentResourceUid}/orderasset`,
      get: (resourceUid: string) => `orderasset/${resourceUid}/`,
      getFromParent: (parentResourceUid: string) => `orderitem/${parentResourceUid}/orderasset`,
    },
    party: {
      create: `orderparty`,
      get: (resourceUid: string) => `orderparty/${resourceUid}`,
    },
    network: {
      create: `ordernetwork`,
      get: (resourceUid: string) => `ordernetwork/${resourceUid}`,
    },
    networkNode: {
      create: `ordernetworknode`,
      get: (resourceUid: string) => `ordernetworknode/${resourceUid}`,
    },
    assetDelivery: {
      create: `orderdeliverydetails`,
      get: (resourceUid: string) => `orderdeliverydetails/${resourceUid}`,
    },
    assetTradeIn: {
      create: `orderassettradein`,
      get: (resourceUid: string) => `orderassettradein/${resourceUid}`,
    },
    financing: {
      create: `orderfinancing`,
      get: (resourceUid: string) => `orderfinancing/${resourceUid}`,
    },
    orderAssociatedParty: {
      create: (parentResourceUid: string) => `order/${parentResourceUid}/orderassociatedparty`,
      get: (resourceUid: string) => `orderassociatedparty/${resourceUid}/`,
      getFromParent: (parentResourceUid: string) => `order/${parentResourceUid}/orderassociatedparty`,
    },
  },
  updateDelegationLevel: "updateDelegationLevel",
  carProduct: "carproduct",
}

export const Masks = {
  dateMask: 'YYYY-MM-DD',
  dateMask_Sys: 'DD/MM/YYYY',
  timestampMask: 'YYYY-MM-DD HH:mm:ss',
  timestampMask_Sys: 'DD/MM/YYYY HH:mm:ss',
  frenchPhoneMask: {
    mask: '(+33)## ## ## ## ##',
    filledmask: '(+33)__ __ __ __ __',
    rule: /^(\(\+33\)\d{2}\s\d{2}\s\d{2}\s\d{2}\s\d{2})$/


  },
  regexOpinion: /^STEP_ENTER(?:_[^_]+)?_OPINION$/,
  emailMask: /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+.[a-zA-Z]{2,4}$/,
  passwordMask: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,100}$/,
  currencyOptions: {
    "locale": "de-DE",
    "currency": "EUR",
    "currencyDisplay": "symbol",
    "precision": 2,
    "hideCurrencySymbolOnFocus": true,
    "hideGroupingSeparatorOnFocus": true,
    "hideNegligibleDecimalDigitsOnFocus": true,
    "autoDecimalDigits": false,
    "useGrouping": true,
    "accountingSign": false
  }
}

const currencyMapper = (key: string) => {
  const mapping: any = {
    'EUR': '€'
  }
  return mapping[key] || key
}

// Configurations

export const globalConfig = {
  table_config: table_config_import,
  notify_config: notify_config_import,
  formatters: formatters_config_import,
  input_rules: input_rules,
  storageKeys,
  currencyMapper,
  capitalize: (value: string) =>
    value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}
//Module

export const globalModuleConfig = {
  assetModule: 'assetModule',
  selectedAsset: 'selectedAsset'
}

export const mandatoryFiels = {
  assetFileds: ['name', 'reference', 'status.type.value', 'type.type.value']
}

export const taskVariables = {
  offer: 'offerId;offerStatus;offerReference;customerName;_offerDecisionProcessId;_clientDecisionProcessId;' +
    'associated_networks_id;customerRessourceUid;customerBirthDate;offerCreationDate;associated_network_nodes_id;' +
    'customerType;offerPhase;customerReference;applicationName;analysisProcessId;_decisionAnalysisProcessId',
  order: ''
}

// Functions

export function edit(value: { isDisabled: boolean; }) {
  value.isDisabled = !value.isDisabled;
}

export function add<T>(items: T[], getter: T) {
  items.push(getter);
}

export function remove<T>(index: number, items: T[], condition = items.length > 0) {
  if (items && condition) {
    items.splice(index, 1);
  }
}

export function addExpandedItem<T extends { objectType: string, isDisabled: boolean }>(items: T[], getter: T) {
  if (items && items.length === 0) {
    items.push(getter);
  }
}

export function removeExpandedItem<T extends { isDisabled: boolean }>(items: T[]) {
  if (items && items.length === 1 && items[0].isDisabled) {
    items.splice(0, 1);
  }
}

export function deleteNonRequiredAttributes<T>(clazz: T, requiredAttributes: string[]) {
  Object.keys(clazz).forEach(field => {
    if (!requiredAttributes.includes(field)) {
      const element = clazz as never;
      delete element[field];
    }
  });
}
export function deleteKeysRecursively(obj: { [key: string]: any }) {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (value && typeof value === 'object') {
          // Recursively call deleteKeys on nested objects
          deleteKeysRecursively(value);

          // Check if the object has the structure that requires key deletion
          if ((value.type && value.type.value === '') || value.resourceUid === '') {
            delete obj[key];
          }
        }
      }
    }
  }
};

export function deleteAttributes<T>(clazz: T, attributesToDelete: string[]) {
  Object.keys(clazz).forEach(field => {
    if (attributesToDelete.includes(field)) {
      const element = clazz as never;
      delete element[field];
    }
  });
}

export function deleteAttributesRecursively<T>(clazz: T, attributesToDelete: string[]) {
  Object.entries(clazz).forEach(([key, value]) => {
    if (attributesToDelete.includes(key)) {
      const element = clazz as never;
      delete element[key];
    }
    else if (typeof value === 'object' && value !== null) {
      deleteAttributesRecursively(value, attributesToDelete)
    }
  });
}

export function removeAttributesRecursively<T>(clazz: T) {
  if (clazz) {
    Object.values(clazz).forEach(value => {
      const _controls = (clazz as never)['_controls'] as Controls;
      if (_controls && _controls.attToRemove) {
        _controls.attToRemove.forEach(att => {
          const element = clazz as never;
          delete element[att];
        });
        delete (clazz as never)['_controls'];
      } else if (typeof value === 'object') {
        removeAttributesRecursively(value);
      }
    });
  }
}

export function formatDateRecursively<T>(data: T, inDateMask: string = Masks.dateMask, outDateMask: string = getMaskFormat()) {
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (moment(value, inDateMask, true).isValid()) {
        const element = data as any;
        element[key] = formatDateLocale(value, inDateMask, 'US', outDateMask);
      }
      else if (typeof value === 'object' && value !== null) {
        formatDateRecursively(value, inDateMask, outDateMask);
      }
    });
  }
}

export const deepClone = (object: object) => {
  return JSON.parse(JSON.stringify(object))
}

export const sleep = async (time: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}
export function mountSelectFieldsRecursively<T>(payload: T, combos: Array<string>) {
  if (!payload) return;

  function processObject(obj: any) {
    Object.entries(obj).forEach(([key, value]: [string, any]) => {
      if (combos.includes(key)) {
        if (!value || typeof value === 'object' && !('type' in value) || !('value' in value.type)) {
          obj[key] = {
            type: {
              value: ''
            }
          };
        } else if ((value as any).type?.value === 'UNKNOWN' || (value as any).type?.value === 'NONE') {
          obj[key].type.value = '';
        }
      } else if (typeof value === 'object' && value !== null) {
        processObject(value); // Recursively process nested objects
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          if (typeof item === 'object' && item !== null) {
            processObject(item); // Recursively process array items
          }
        });
      }
    });
  }

  processObject(payload);
}



export function mountSelectFields<T>(payload: T, combos: Array<string>) {
  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (combos.includes(key)) {
        const attribute = payload as any;
        if (attribute[key]) {
          if (!attribute[key].type) {
            attribute[key] = {
              type: {
                value: attribute[key].resourceUid
              }
            }
          }
          else if (!attribute[key].type.value) {
            attribute[key] = {
              type: {
                value: ''
              }
            }
          }
        }
      }
      else if (typeof value === 'object' && value !== null) {
        mountSelectFields(value, combos)
      }
    })
  }
}

export const unmountSelectFieldsV2 = <T>(payload: T, combos: Array<string>) => {
  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (combos.includes(key)) {
        const attribute = payload as any;
        if (!attribute[key]?.type) {
          console.warn(`attribute ${key} is not a ComboBox`)
        }
        else {
          if (attribute[key].type.value && attribute[key].type.value !== '') {
            attribute[key].resourceUid = attribute[key].type.value
          }
          delete attribute[key].type
        }
      }
      else if (typeof value === 'object') {
        unmountSelectFieldsV2(value, combos)
      }
    })
  }
}

export const unmountSelectFields = <T>(payload: T) => {
  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (isComboBox(value) && key === 'type') {
        const attribute = payload as any;
        attribute.resourceUid = attribute[key].value
        delete attribute[key]
      }
      else if (typeof value === 'object') {
        unmountSelectFields(value)
      }
    })
  }
}

export const isComboBox = (object: unknown): object is ComboBox => {
  if (object && typeof object === "object") {
    return "value" in object
  }
  return false
}

export const isParty = (object: unknown): object is Person | Organization => {
  if (object && typeof object === "object") {
    return "type" in object;
  }
  return false;
}

export const isPerson = (object: unknown): object is Person => {
  if (object && typeof object === "object") {
    return "familyName" in object;
  }
  return false;
}

export const isOrganization = (object: unknown): object is Organization => {
  if (object && typeof object === "object") {
    return "siren" in object;
  }
  return false;
}

export const isUON = (object: unknown): object is UniformOdmName => {
  if (object && typeof object === "object") {
    return "resourceUid" in object;
  }
  return false;
}

export function getMaskFormat() {
  return i18n.global.t('mask.format')
}

export function getDateTimeMask() {
  return i18n.global.t('mask.dateTime')
}

export function getMaskInput() {
  return i18n.global.t('mask.input')
}
export function wrapCsvValue(val: string, formatFn: string|undefined, row: string) {
  let formatted = formatFn !== void 0 ? formatFn : val;

  formatted = formatted === void 0 || formatted === null ? "" : String(formatted);

  formatted = formatted.split('"').join('""');

  return `${formatted}`;
}


export function exportToCsv(rows: any , columns: any){
    const content = [columns.map((col:any) => wrapCsvValue(i18n.global.t(col.label), i18n.global.t(col.label),  i18n.global.t(col.label))).join(";")]
    .concat(
      rows.map((row:any) =>
        columns
          .map((col: any) =>
            wrapCsvValue(
              typeof col.field === "function"
                ? col.field(row)
                : row[col.field === void 0 ? col.name : col.field],
              col.format,
              row
            )
          )
          .join(";")
      )
    )
    .join("\r\n");

  const status = exportFile("report.csv", '\ufeff'+content, "text/csv");

  if (status !== true) {
    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
      message: "Browser denied file download...",
      color: "negative",
      icon: "warning",
    });
  }else{
     Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    message:i18n.global.t('reportScreen.downloadCompletedSuccessfully'),
                    color: "positive",
                  });
  }
}

export function setPicklistResourceUid<T>(clazz: T) {
  if (clazz) {
    Object.values(clazz).forEach(value => {
      if (value.type && value.type?.value) {
        value.resourceUid = value.type?.value;
      }
      else if (typeof value === 'object') {
        setPicklistResourceUid(value);
      }
    });
  }
}

export function copyProperties(source: any, target: any) {
  Object.keys(source).forEach((rowKey) => {
    Object.keys(target).forEach((key) => {
      if (key.toLowerCase() === rowKey.toLowerCase()) {
        if (target[key] && target[key].type) {
          target[key].type.value = source[rowKey];
        }
        else {
          target[key] = source[rowKey];
        }
      }
    })
  })
}

/**
 * This function selects specified attributes from a given object.
 *
 * @param {any} obj - The object to pick attributes from.
 * @param {string[]} attributes - An array of attribute names to pick from the object.
 * @return {Record<string, unknown>} Returns a new object with only the picked attributes.
 */
export const pickAttributes = (obj: any, ...attributes: string[]): Record<string, unknown> => {
  return attributes.reduce((pickedAttributes: Record<string, unknown>, att: string) => {
    if (att in obj) {
      pickedAttributes[att] = obj[att];
    }
    return pickedAttributes;
  }, {});
}

export const chunkList = (array: any[], chunkSize: number) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk)
  }
  return result
}

export function parseJwt(token: string | null) {
  const base64Url = token?.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = '';
  if (base64) {
    jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  return JSON.parse(jsonPayload);
}

export function tokenIsValid() {
  const token = sessionStorage.getItem('token');
  if (token) {
    const tokenData = parseJwt(token);
    const now = new Date().getTime() / 1000;
    return tokenData.exp > now;
  }
  else {
    return false;
  }
}

export function initControl(attToRemove = ['isDisabled'], dateFormat = 'yyyy-mm-dd'): Controls {
  return {
    attToRemove, dateFormat
  }
}

export function getOperator(table: string, field: string, operator: string, value?: any, parameter?: string, inParameters?: string[]) {
  return {
    "expression": {
      "table": table,
      "field": field,
      "operator": operator,
      "value": value,
      "parameter": parameter,
      "inValue": (inParameters && inParameters.length > 0) ? {
        "values": inParameters
      } : null,
    }
  }
}



// Rest and session
export const SESSION_TOKEN_KEY = "token";

export function setToken(token: string): void {
  if (sessionStorage) {
    sessionStorage.setItem(SESSION_TOKEN_KEY, token);
  }
}

export function getTokenStorage(): string {
  let token = "";
  if (sessionStorage) {
    const _token = sessionStorage.getItem(SESSION_TOKEN_KEY);
    token = _token ? _token : token;
  }
  return token;
}

export function cleanSession() {
  if (sessionStorage) {
    sessionStorage.removeItem(SESSION_TOKEN_KEY);
  }
}

export function headers(params = {}) {
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);   // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJVc2VyIEFub255bW91cyIsImF1dGgiOiJST0xFX0FETUlOIiwidXNlclJlc291cmNlVWlkIjoiZjY4MDk3NmEtYTBlYS00ZmYzLWFhNjUtOTEwYTg1MWRmZGE2IiwidXNlcm5hbWUiOiJmNjgwOTc2YS1hMGVhLTRmZjMtYWE2NS05MTBhODUxZGZkYTZAVFdERGVtbyIsImFwcGxpY2F0aW9uTmFtZSI6IlRXRERlbW8iLCJmaXJzdE5hbWUiOiJVc2VyIiwibGFzdE5hbWUiOiJBbm9ueW1vdXMiLCJlbWFpbCI6ImY2ODA5NzZhLWEwZWEtNGZmMy1hYTY1LTkxMGE4NTFkZmRhNkBUV0REZW1vLmZyIiwidGVuYW50U3lzdGVtVWlkIjoiVGVuYW50IiwidGVuYW50UmVzb3VyY2VVaWQiOiJkZW1vIiwiZXhwIjoyNjQ5MTg0NTM4fQ.Vtb5tOBJWE_u_EacVzCmkz1kFh8NSg6awjM5ec0imyZFWmQOjQdL7bfyxqkSw1KnKYTZ_qyAIzmEcvj3c4fpKA"/*getTokenStorage()*/
  if (token) {
    return { Authorization: 'Bearer ' + token, ...params };
  } else {
    router.push('/login')
  }
}

export async function upsert(system_uid: string, body: any, path: string, parentResourceUid?: string, daaqResourceUid?: string): Promise<string> {
  let resourceUid = body.resourceUid
  if (!resourceUid) {
    const url = `/${system_uid}/api/1/${system_uid}/${path}/`;
    if (path === 'emailcontact') {
      body.objectType = "odm.party.contactmechanism.emailcontact"
      deleteAttributes(body, ['identifier', 'phoneNumber', 'acceptSMS'])
    }
    resourceUid = (await api(store?.state?.authModule?.daaqResourceUid).post(url, body)).data.resourceUid
  } else {
    const url = !parentResourceUid
      ? `/${system_uid}/api/1/${system_uid}/${path}/${resourceUid}/`
      : `/${system_uid}/api/1/${system_uid}/${parentResourceUid}/${path}/${resourceUid}/`;
    resourceUid = (await api().put(url, body)).data.resourceUid
  }
  return resourceUid
}
export async function upsertWithDataMessageReturn(system_uid: string, body: any, path: string, parentResourceUid?: string) {
  if (body) {
    const url = `/${system_uid}/api/1/${system_uid}/${path}/`;
    const config = {
      headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
      }
    };
    try {
      return (await api().post(url, body, config)).data
    } catch (e) {
      return {
        message: 'IBAN is incorrect, please verify IBAN'
      }
    }

  }
}

export async function upsertWithDataReturn(system_uid: string, body: any, path: string, parentResourceUid?: string, daaqResourceUid?: string) {
  if (!body.resourceUid) {
    const url = `/${system_uid}/api/1/${system_uid}/${path}/`;
    return (await api(daaqResourceUid).post(url, body)).data;
  } else {
    const url = !parentResourceUid
      ? `/${system_uid}/api/1/${system_uid}/${path}/${body.resourceUid}/`
      : `/${system_uid}/api/1/${system_uid}/${parentResourceUid}/${path}/${body.resourceUid}/`;
    return (await api().put(url, body)).data;
  }
}

export const createOffer = async (payload: any, uri: { systemUid: string, path: string }, daaqResourceUid?: string) => {
  return payload.resourceUid
    ? (await api().put(`/${uri.systemUid}/api/1/${uri.path}${payload.resourceUid}/`, payload)).data
    : (await api(daaqResourceUid).post(`/${uri.systemUid}/api/1/${uri.path}`, payload)).data
}

//{"title":"Internal Server Error","status":500,"detail":"Error loading on com.teamwill.leaseforge.offer.model.contract.quote.ebean.SimpleQuoteEntity.periodBetween2Installments"}

export async function getEntity(system_uid: string, path: string, resourceUid?: string) {
  const url = `/${system_uid}/api/1/${system_uid}/${path}/${resourceUid}/`;
  return (await api().get(url)).data;
}

export const createUser = async (payload: AuthUser, uri: { systemUid: string, path: string }) => {
  if (payload.daaqList) {
    delete payload.daaqList
  }
  removeAttributesRecursively(payload);
  return axiosAnonymousPostRequest(uri, payload);
}

export const validateCode = async (payload: unknown, uri: { systemUid: string, path: string }) => {
  return axiosAnonymousPostRequest(uri, payload);
}

export const login = async (payload: AuthUser, uri: { systemUid: string, path: string }) => {
  return (await axios.post(`${settings.api_url}/${uri.systemUid}/api/1/${uri.path}`, payload)).data.token;
}

export async function requestForgotPasswordValidationCode(payload: { login?: string }, uri: { systemUid: string, path: string }) {
  return axiosAnonymousPostRequest(uri, payload);
}

export async function resetPassword(payload: { login?: string, smsCode?: string, password?: string }, uri: { systemUid: string, path: string }) {
  return axiosAnonymousPostRequest(uri, payload);
}

async function axiosAnonymousPostRequest(uri: any, payload: any) {
  const promise = await axios.post(settings.auth_anonymous_api_url)
  return (await axios.post(`${settings.api_url}/${uri.systemUid}/api/1/${uri.path}`, payload, {
    headers: {
      'Authorization': 'Bearer ' + promise.data.token,
    }
  })).data;
}

export const groupBy = (xs: any, key: any) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupByFn = <T>(arr?: T[], fn?: (item: T) => any) => {
  return arr?.reduce<Record<string, T[]>>((prev, curr) => {
    let groupKey;
    if (fn) {
      groupKey = fn(curr);
    }
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

export const summingUp = <T>(arr: T[], fn: (item: T) => number | undefined) => {
  return Number(arr.reduce((prev, curr) => {
    return prev + (fn(curr) || 0)
  }, 0));
}

export const nonNullHelper = (item: any, attr: string, callback: any, nullCallback?: any) => {
  if (item[attr]) {
    callback(item)
  } else if (nullCallback) {
    nullCallback()
  }
}

export const mapResourceUid = (item: any) => item.resourceUid

export function parseTaskVariables(variables: any, taskDefinition?: string): any {
  return getTaskModule(variables) !== TaskModule.ORDER ? parseTaskVariablesOffer(variables, taskDefinition) : parseTaskVariablesOrder(variables, taskDefinition)
}

export function getTaskModule(variablesString: string | any) {
  let module = TaskModule.OFFER
  if (typeof variablesString === 'string') {
    const variables = JSON.parse(variablesString)
    if (variables.module?.value) {
      module = variables.module.value === TaskModule.ORDER ? TaskModule.ORDER : TaskModule.OFFER
    }
  } else {
    if (variablesString.module) {
      if (variablesString.module === TaskModule.ORDER || variablesString.module.value === TaskModule.ORDER) {
        module = TaskModule.ORDER
      }
    }
  }
  return module
}

export function parseTaskVariablesOffer(variablesString: string | any, taskDefinition?: string): any {
  const variables = typeof variablesString === 'string' ? JSON.parse(variablesString) : variablesString
  let decisionProcessId = ''
  const msgError = 'Error: Variable not present'
  if ([
    TaskDefinition.ENTER_DECISION,
    TaskDefinition.DELEGATION_LEVEL,
    TaskDefinition.ANALYSIS
  ].includes(taskDefinition as TaskDefinition)) {
    decisionProcessId = variables._offerDecisionProcessId.value
  } else if (taskDefinition === TaskDefinition.ENTER_CUSTOMER_DECISION) {
    decisionProcessId = variables._clientDecisionProcessId.value;
  } else {
    decisionProcessId = msgError
  }

  const associated_networks_id = variables.associated_networks_id?.value || msgError
  const offerReference = variables.offerReference?.value || msgError
  const customerRessourceUid = variables.customerRessourceUid?.value || msgError
  const offerStatus = variables.offerStatus?.value || msgError
  const customerName = variables.customerName?.value || msgError
  const customerBirthDate = variables.customerBirthDate?.value || msgError
  const offerCreationDate = variables.offerCreationDate?.value || msgError
  const associated_network_nodes_id = variables.associated_network_nodes_id?.value || msgError
  const customerType = variables.customerType?.value || msgError
  const offerPhase = variables.offerPhase?.value || msgError
  const customerReference = variables.customerReference?.value || msgError
  const offerId = variables.offerId?.value || msgError
  const applicationName = variables.applicationName?.value || msgError

  const analysisProcessId = variables.analysisProcessId?.value || msgError
  const _decisionAnalysisProcessId = variables._decisionAnalysisProcessId ? variables._decisionAnalysisProcessId.value : null
  const _checkPointsId = variables._checkPointsId ? variables._checkPointsId.value : null

  const offer = variables.offer?.value || msgError
  let accessoryProductInfo = msgError
  let assetInfo = msgError
  let partyInfo = msgError
  let quoteInfo = msgError
  let offerInfo = msgError

  if (variables.offer && variables.offer.value && variables.offer.value.indexOf('{') === 0) {
    const offerParsed = JSON.parse(variables.offer.value)
    accessoryProductInfo = offerParsed.accessoryProductInfo
    assetInfo = offerParsed.assetInfo
    partyInfo = offerParsed.partyInfo
    quoteInfo = offerParsed.quoteInfo
    offerInfo = offerParsed.offerInfo
  }
  const additional_action_items = variables.additional_action_items ? variables.additional_action_items.value : null
  const SignatureMethod = variables.SignatureMethod ? variables.SignatureMethod.value:null
  return {
    module: TaskModule.OFFER,
    associated_networks_id,
    offerReference,
    customerRessourceUid,
    offerStatus,
    customerName,
    customerBirthDate,
    offer,
    offerCreationDate,
    associated_network_nodes_id,
    customerType,
    offerPhase,
    customerReference,
    offerId,
    applicationName,
    accessoryProductInfo,
    assetInfo,
    partyInfo,
    quoteInfo,
    offerInfo,
    decisionProcessId,
    analysisProcessId,
    _decisionAnalysisProcessId,
    _checkPointsId,
    additional_action_items,
    SignatureMethod
  }
}

export function parseTaskVariablesOrder(variablesString: string | any, taskDefinition?: string): any {
  const variables = typeof variablesString === 'string' ? JSON.parse(variablesString) : variablesString
  const isPerson = variables.customerType?.value === 'person';


  return {
    orderId: variables.orderId?.value || variables.orderId,
    module: variables.module?.value || variables.module,
    associated_networks_id: variables.associated_networks_id?.value || variables.associated_networks_id,
    orderStatus: variables.orderStatus?.value || variables.orderStatus,
    orderCreationDate: variables.orderCreationDate?.value || variables.orderCreationDate,
    customerRessourceUid: isPerson ? (variables.customerRessourceUid?.value || variables.customerRessourceUid) : undefined,
    customerName: variables.customerName?.value || variables.customerName,
    customerBirthDate: isPerson ?  (variables.customerBirthDate?.value || variables.customerBirthDate) : undefined,
    associated_network_nodes_id: variables.associated_network_nodes_id?.value || variables.associated_network_nodes_id,
    customerType: variables.customerType?.value || variables.customerType,
    customerReference: variables.customerReference?.value || variables.customerReference,
    orderReference: variables.orderReference?.value || variables.orderReference,
    applicationName: variables.applicationName?.value || variables.applicationName,
    additional_action_items: variables.additional_action_items ? variables.additional_action_items.value : null
  }
}

export function mountNoticeMessageDataInfo(noticeOffer?: Offer): any {

  let party: any
  noticeOffer?.associatedParties!.forEach(function (item) {
    if (item.associatedParty?.role_code === 'CUSTOMER') {
      party = item.associatedParty
    }
  })

  const third: any = (noticeOffer?.associatedParties ? noticeOffer?.associatedParties[0].associatedParty?.third : undefined)

  const proposal: any = noticeOffer?.proposals[0]
  const asset: any = proposal.proposalItems[0].proposalAssets[0]

  const objNotice = {
    reference: noticeOffer?.reference || '',
    associatedNetworkNodesId: noticeOffer?.associatedNetworkNodes?.resourceUid || '',
    firstName: third.firstName || '',
    siren: '',
    postalCode: party.third.person?.addresses.length ? party.third.person.addresses[0].address.postalCode.postalCode || '' : '',
    positionType: party.third.jobs?.length ? party.third.jobs[0].positionType.type.value || '' : '',
    activityId: '',
    financedValue: proposal.proposalItems[0].financedValue ? proposal.proposalItems[0].financedValue.toString() : '',
    proposalItem: '',
    numberOfPaymentTerm: proposal.proposalItems[0].numberOfPaymentTerm ? proposal.proposalItems[0].numberOfPaymentTerm.toString() : '',
    periodBetweenTwoInstallmentsUnit: proposal.proposalItems[0].periodBetween2Installments.duration ? proposal.proposalItems[0].numberOfPaymentTerm.toString() : '',
    proposalItemEndDate: proposal.proposalItems[0].endDate || '',
    firstPaymentWTax: proposal.proposalItems[0].firstPaymentWTax ? proposal.proposalItems[0].firstPaymentWTax.toString() : '',
    partyClass: '',
    decisionReserveCode1: '',
    decisionReserveCode2: '',
    decisionReserveCode3: '',
    decisionReserveCode4: '',
    decisionReserveCode5: '',
    decisionReserveCode6: '',
    decisionProcessStatusCode: '',
    decisionReasonCode: '',
    decisionMakerResourceUid: '',
    opinionMakerResourceUid: '',
    decisionDate: '',
    commercialName: '',
    analysisProcessId: '',
    addressLine1: party.third.person?.addresses.length ? party.third.person.addresses[0].line1 || '' : '',
    street: party.third.person?.addresses.length ? party.third.person.addresses[0].street || '' : '',
    commercialRegister: '',
    assetQuantity: asset.length ? asset.length.toString() : '',
    assetDescription: asset.description || '',
    assetBrand: asset.brand || '',
    assetRange: asset.range || '',
    assetAmountWoTax: asset.amountWoTax.amount ? asset.amountWoTax.amount.toString() : '',
    assetAmountWTax: asset.amountWTax.amount ? asset.amountWoTax.amount.toString() : '',
    assetItemAmountWoTax: ''
  }

  return objNotice
}

export function mountNoticeMessageItems(noticeTemplate?: NoticeTemplate): any {
  const itemsNotifi: any = []
  const arrItem: any = (noticeTemplate?.items ? noticeTemplate?.items : [])
  arrItem.forEach((item: { validity: any; fileName: any; filePath: any; qualification: any; selected: any }) => {
    if (item.selected) {
      const itNotif: any = {
        validity: item.validity,
        fileName: item.fileName,
        filePath: item.filePath,
        qualification: item.qualification,
        selected: item.selected
      }
      itemsNotifi.push(itNotif)
    }
  })

  const itemsNotice: any = []
  itemsNotice.push({
    resourceUid: noticeTemplate?.resourceUid,
    objectType: "odm.contactmechanism.packnoticetemplateinfo",
    systemUid: "odm-contactmechanism",
    businessData: null,
    daaq: null,
    validity: null,
    fileName: noticeTemplate?.fileName,
    filePath: null,
    isPack: (noticeTemplate?.isPack ? noticeTemplate?.isPack : false),
    items: itemsNotifi
  })

  return itemsNotice
}

export function mountNoticeMessage(noticeOffer?: Offer, noticeTemplate?: NoticeTemplate): NoticeMessage {

  const noticeMessage: any = {
    objectType: "odm.contactmechanism.communicationrequest.noticemessage",
    resourceUid: "TWDDemo",
    systemUid: "odm-contactmechanism",
    clientApplication: {
      resourceUid: "TWDDemo"
    },
    parameters: {},
    dataInfo: JSON.stringify(mountNoticeMessageDataInfo(noticeOffer)),
    items: mountNoticeMessageItems(noticeTemplate)
  }

  return noticeMessage
}

export const useDate = {
  currDate: moment(new Date()).format('YYYY-MM-DD'),
  sqlFormat: <T>(date: null | string | T | Date = new Date()) => {
    if (!date) return null;
    else return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  },
  dateLocale: <T>(date: T, inMask: string, inLocale: string, outMask = Masks.dateMask) => {
    return moment(date, inMask, inLocale).format(outMask)
  },
  sysFormat: (date: string) => moment(date).format('DD/MM/YYYY'),
  withPattern: <T>(date: T, pattern: string) => moment(date).format(pattern),
  isPeriodValid(date: string, from: string, until: string) {
    return moment(date).isBetween(
      moment(from), moment(until ?? this.currDate), undefined, '[]'
    )
  },
  getDays: (from: string, until: string) => {
    const start = moment(useDate.sqlFormat(from));
    const end = moment(useDate.sqlFormat(until));
    const duration = moment.duration(end.diff(start));
    return duration.asDays();
  }
}

export const moveElementInArray = <T>(arr: Array<T>, fromIndex: number, toIndex: number): void => {
  const element = arr.splice(fromIndex, 1)[0];
  arr.splice(toIndex, 0, element);
}

export function getOfferFromState(state: RootState, routePath?: RouteRecordName | null | undefined) {
  if (routePath === 'TaskDetail') {
    return state.taskModule.offer;
  }
  if (routePath === 'demand-edit') {
    return state.demandModule.offer;
  }
  if (routePath === 'mo-demand-detail') {
    return state.middleOfficeModule.offer;
  }
}


export function getOrderFromState(state: RootState, routePath?: RouteRecordName | null | undefined) {
  if (routePath === 'TaskDetail') {
    return state.taskModule.order;
  }
  if (routePath === 'order-edit') {
    return state.orderModule.order;
  }
}

/**
 * this function will display success or error after reply or transfer msg
 * @param error
 */
export function notifyReplytransferMsg(error: boolean, context: string) {
  if (error) {
    if (context == "reply") {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t(
          "communication.comNotification.messageSent"
        ),
        color: "positive",
      });
    } else {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t(
          "communication.comNotification.messageForwarded"
        ),

        color: "positive",
      });
    }
  }
  else {
    if (context == "reply") {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t(
          "communication.comNotification.failedMessageSent"
        ),
        color: "negative",
      });
    }
    else {
      Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t(
          "communication.comNotification.failedMessageForwarded"
        ),
        color: "negative",
      });
    }
  }
}
/**
 * mapping users and groups to UON objects
 * @param destinations
 */
export function convertToUON(destinations: any[]): any[] {
  return destinations.map(destination => {
    const filteredDestination = Object.keys(destination).reduce((obj: any, key: string) => {
      if (key !== 'firstName' && key !== 'lastName' && key !== 'username') {
        obj[key] = destination[key];
      }
      return obj;
    }, {});
    return { recipient: filteredDestination };
  });
}

export function translateGroupName(UID: any) {
  return i18n.global.t(`middleOffice.decision.profil.${UID}`)
}

export async function getGuarantee(system_uid: string, guaranteeType: string, guaranteeId: string): Promise<string> {
  let guaranteeTypeReformat = ''
  switch (guaranteeType) {
    case 'product-PersonalGuarantee':
      guaranteeTypeReformat = 'personalguarantee';
      break;
    case 'product-BankGuarantee':
      guaranteeTypeReformat = 'bankguarantee';
      break;
    case 'product-Mortgage':
      guaranteeTypeReformat = 'mortgage';
      break;
    case 'product-PropertyPledging':
      guaranteeTypeReformat = 'propertypledging';
      break;
    case 'product-BuyBack':
      guaranteeTypeReformat = 'buyback';
      break;
    default:
      break;
  }
  const url = `/${system_uid}/api/1/${system_uid}/${guaranteeTypeReformat}/${guaranteeId}/`
  const selectedGuarantee = (await api().get(url)).data
  return selectedGuarantee
}
/**
 * orderingByDateTime a list
 */
export const orderingByDateTime = {
  /**
   * in a ascending way
   * @param list
   */
  ascending: <T>(list: T[]) => {
    return list.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())
  },
  /**
   * in a descending way
   * @param list
   */
  descending: <T>(list: T[]) => {
    return list.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
  }
}

export const ordering = {
  /**
   * Sorts the given list in ascending order based on the specified field.
   * @template T
   * @param {T[]} list - The list to be sorted.
   * @param {string} field - The field to sort the list by.
   * @return {T[]} - The sorted list.
   */
  ascending: <T>(list: T[], field: string): T[] => {
    return list.sort((a: any, b: any) => a[field] - b[field]);
  },

  /**
   * Sorts the given list in descending order based on the specified field.
   * @template T
   * @param {T[]} list - The list to be sorted.
   * @param {string} field - The field to sort the list by.
   * @return {T[]} - The sorted list in descending order.
   */
  descending: <T>(list: T[], field: string): T[] => {
    return list.sort((a: any, b: any) => b[field] - a[field]);
  }
}

/**
 * provides the correct operator to be used in search filters
 * @param params
 */
export const operator = <T>(params: { val: T, table: string, dbField: string }) => {
  const { dbField, val, table } = params;

  /**
   * Validates the input values for `val`, `table`, and `dbField`.
   *
   * @throws {Error} Throws an error if `val` is undefined.
   * @throws {Error} Throws an error if `table` is undefined.
   * @throws {Error} Throws an error if `dbField` is undefined.
   */
  const validate = () => {
    if (typeof val !== 'boolean' && !val) throw new Error('field is undefined!')
    if (!table) throw new Error('table is undefined!')
    if (!dbField) throw new Error('dbField is undefined!')
  }
  validate();

  /**
   * Loops through the given field and its nested arrays to add non-array values to the inParams array as strings.
   *
   * @param field - the field to loop through
   * @return {string[]} the array of non-array values converted to strings
   */
  const setInFields = (field: T): string[] => {
    const inParams: string[] = [];

    /**
     * Sets the values of the "inParams" array by recursively traversing the given "field" array.
     *
     * @param field - The array to be traversed and added to "inParams".
     */
    const setInParams = (field: T) => {
      if (Array.isArray(field)) {
        field.forEach((val: T) => {
          if (Array.isArray(val)) {
            setInParams(val);
          } else {
            inParams.push(`'${val}'`);
          }
        });
      }
    }

    setInParams(field);

    return inParams;
  }

  const LIKE = getOperator(
    `lower(${table}`,
    `${dbField})`,
    "LIKE",
    getLikeValue(val).toLowerCase(),
    dbField);

  const EQUAL = getOperator(
    table,
    dbField,
    "EQUAL",
    getEqualValueStringOrBoolean(val),
    dbField);

  const IN = getOperator(
    table,
    dbField,
    "IN",
    undefined,
    undefined,
    setInFields(val));

  const GREATER_THAN_EQUAL = getOperator(
    table,
    dbField,
    "GREATER_THAN_EQUAL",
    `'${useDate.sqlFormat(val)}'`,
    dbField);

  const LESS_THAN_EQUAL = getOperator(
    table,
    dbField,
    "LESS_THAN_EQUAL",
    `'${useDate.sqlFormat(val)}'`,
    dbField);

  return { LIKE, EQUAL, IN, GREATER_THAN_EQUAL, LESS_THAN_EQUAL };
}

/**
 * mounts the and conditions to be used in the search filter query
 * @param operators
 * @param qc_header
 */
export const setOtherFilters = <T>(operators: T[], qc_header: QcHeader) => {
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
}

/**
 * remove invalid characters from a money value
 * e.g.: 2 349,00 to 2349.00
 * @param money
 */
export const fixMoneyValue = (money = "0") =>
  money.replaceAll(",", ".").replace(/[^0-9.-]+/g, "");

/**
 * translate combobox items
 * @param arr
 * @param i18nKey
 * @returns {ComboBoxItem[]}
 */
export const translateComboBox = ((arr: ComboBoxItem[], i18nKey: string) => {
  const translatedItems = arr.map((item: ComboBoxItem) => ({
    ...item,
    label: i18n.global.t(`${i18nKey}.${item.value}`)
  }));
  translatedItems.sort((a, b) => a.label.localeCompare(b.label));
  return translatedItems;
})
/**
 * translate combobox items
 * @param arr
 * @param i18nKey
 * @returns {PicklistItem[]}
 */
export const translatePicklist = ((arr: PicklistItem[], i18nKey: string) => {
  const translatedItems = arr.map((item: PicklistItem) => ({
    ...item,
    label: i18n.global.t(`${i18nKey}.${item.id}`)
  }));
  translatedItems.sort((a, b) => a.label.localeCompare(b.label));
  return translatedItems;
})



export async function initanalysisData(offerParam: any, dynamicKeys: Record<string, any>) {
  const offer = deepClone(offerParam)
  const proposal = offer.proposals[0]
  const proposalItem = proposal.proposalItems[0]
  const accessoryProductInfo = proposalItem.proposalAccessories
    .map((item: any) => {
      const {
        amountWoTax,
        taxValue,
        basisValue,
        quantity,
        proposalAccessoryCalculationMethodOption,
        proposalAccessoryPaymentDatesOption
      } = item
      return JSON.stringify({
        quantity,
        basis: basisValue,
        currency: amountWoTax.currency,
        paymentDatesOption: proposalAccessoryPaymentDatesOption.resourceUid,
        amount: amountWoTax,
      })
    })

  const assetInfo = proposal.proposalItems
    .map((item: any) => item.proposalAssets)
    .reduce((a: any, b: any) => {
      removeAttributesRecursively(b)
      deleteAttributesRecursively(b, ['isDisabled', 'config'])
      a.push({ assetData: JSON.stringify(deepClone(b)) });
      return a
    }, [])

  const partyInfo = (offer.associatedParties || []).map((offerAssociatedParty: any) => {
    const { role_code } = offerAssociatedParty
    const { third } = offerAssociatedParty.associatedParty
    const type = third.type.id
    const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
    removeAttributesRecursively(body)
    deleteAttributesRecursively(body, ['isDisabled', 'config'])
    return {
      partyData: JSON.stringify({
        type,
        role: role_code || 'CLIENT',
        ...body
      })
    }
  })

  const quoteInfotemp = deepClone(proposal)
  delete quoteInfotemp.resourceUid
  delete quoteInfotemp.systemUid
  delete quoteInfotemp.objectType
  delete quoteInfotemp.businessData
  delete quoteInfotemp.proposalDecisions


  deleteAttributesRecursively(quoteInfotemp, ['isDisabled', 'config'])
  const accessories = accessoryProductInfo
  const quoteInfo = JSON.stringify(quoteInfotemp)
  const offerObject = {
    quoteInfo,
    partyInfo,
    assetInfo,
    accessories,
    ...dynamicKeys, // Spread the dynamic keys into the object

  };
  return JSON.stringify(offerObject)
}

export const getAnalysesDataRequest = (analysisData: any) => {
  const analysisDataObject = analysisData
  // Add dynamic keys to the object
  const enrichedRequestedData = {
    "analysisData": analysisDataObject,
    "objectType": "",
    "systemUid": "",
    "resourceUid": "",
    "businessData": {},
    "daaq": ""
  }
  return enrichedRequestedData
}

export const generateQuestionsByCategory = (questions: any) => {
  const questionsByCategory: any = {};

  questions.forEach((question: any) => {
    const category = question.question_category_i18n_code;

    if (!questionsByCategory[category]) {
      questionsByCategory[category] = {};
    }

    const questionKey = question.question_i18n_code;

    if (!questionsByCategory[category][questionKey]) {
      questionsByCategory[category][questionKey] = {
        ...question,
        answerOptions: [],
      };
    }

    questionsByCategory[category][questionKey].answerOptions.push({
      answer_option_order: question.answer_option_order,
      answer_option_id: question.answer_option_id,
      answer_option_i18n_code: question.answer_option_i18n_code,
      question_id: question.question_id
    });
  });

  return questionsByCategory;
}

export const generateRatiosByCategory = (ratios: any) => {
  const ratiosByCategory: any = {};

  ratios.forEach((ratio: any) => {
    const category = ratio.category_code;

    if (!ratiosByCategory[category]) {
      ratiosByCategory[category] = {};
    }

    const ratioKey = ratio.ratio_id;

    if (!ratiosByCategory[category][ratioKey]) {
      ratiosByCategory[category][ratioKey] = {
        ...ratio
      };
    }

  });

  return ratiosByCategory;
}


export const convertRatiodataToRatiovalue = (ratios: any) => {
  // Mapper chaque objet "odm.analysis.ratiodata" en "odm.analysis.ratiovalue"
  const convertedRatios = ratios.map((ratiodata: any) => {
    const ratiovalue = {
      ...ratiodata,
      objectType: "odm.analysis.ratiovalue",

    };

    return ratiovalue;
  });

  return convertedRatios;
}



export function checkMandatoryFields(storeLocation: any, mandatoryFields: any) {
  const selectedAsset = storeLocation.reduce((obj: any, key: any) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, store.state);
  const missingFields = mandatoryFields.filter((field: any) => {
    const fieldPath = field.split('.');
    const value = fieldPath.reduce((obj: any, key: any) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, selectedAsset);

    return value === undefined || value === null || value === '';
  });

  return missingFields;
}

/**
 * Generates a random alphanumeric string and returns a substring based on the specified position.
 *
 * @param {number} position - the position to start the substring
 * @return {string} the substring of the generated random alphanumeric string
 */
export const doRef = (position: number): string => (Math.random() + 1).toString(36).substring(position)


export function removeDuplicatesPreferences(preferences: any[]): any[] {
  const uniquePreferences: Set<string> = new Set();
  const newPreferences: any[] = [];

  for (const preference of preferences) {
    const preferenceKey: string = `${preference.preferenceType.resourceUid}-${preference.address.resourceUid}`;

    if (!uniquePreferences.has(preferenceKey)) {
      uniquePreferences.add(preferenceKey);
      newPreferences.push(preference);
    }
  }

  return newPreferences;
}

export async function buildGeneriqueRequestData(stateParam: any, dynamicKeys?:any): Promise<any> {
  const state = deepClone(stateParam)
  const { offer } = state
  const proposalList: Proposal[] = await getProposalInfoScale(store.state)
  const proposal = proposalList[0]
  const assetList = proposal.proposalItems
    .map(item => item.proposalAssets)
    .reduce((a, b) => {
      a.push(deepClone(b));
      return a
    }, [])
  const accessoryProductInfo: AccessoryProductInfo[] = []
  proposal.proposalItems
    .map(item => item.proposalAccessories.forEach(accessories => {
      accessoryProductInfo.push({
        currency: 'EUR',
        quantity: accessories.quantity,
        calculationMethodOption: accessories.proposalAccessoryCalculationMethodOption?.resourceUid,
        paymentDatesOption: accessories.proposalAccessoryPaymentDatesOption?.resourceUid,
        basis: String(accessories.basisValue),
        amount: accessories.amountWoTax,
        annualRate: accessories.rate,
      })
    }))

  const partDirig: any = []
  let partyInfo: any = []
  if (offer && offer.associatedParties) {
    partyInfo = offer.associatedParties
      .sort((offerAssociatedParty: any) => offerAssociatedParty.associatedParty.role_code === 'CUSTOMER' ? -1 : 0)
      .map((offerAssociatedParty: any) => {
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], partyInfo)
        if (!type.includes("Organization")) {
          body.birthDate = body.birthDate || moment().format(Masks.dateMask)
        } else if (body.manager != undefined) {
          partDirig.push(body.manager)
        }
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        return {
          partyData: JSON.stringify({
            type,
            role: role_code || 'CLIENT',
            ...body
          })
        }
      })


    if (partDirig.length > 0) {
      partyInfo.push({
        partyData: JSON.stringify({
          type: 'party-FrenchPerson',
          role: partDirig[0].roles[0].role.resourceUid,
          flagInsured: true,
          ...partDirig[0]
        })
      })
    }
  }
  const assetInfo = assetList.map(asset => {
    removeAttributesRecursively(asset)
    return {
      assetData: JSON.stringify(asset)
    }
  })
  removeAttributesRecursively(proposal)

  proposal.proposalItems.forEach((el: any) => {
    for (const key in el) {
      if (el[key] === undefined) {
        el[key] = null
      }
    }
    el.contractDuration = getContractDuration(el)
    el.ageEndOfContract = getAgeEndOfContract(el, partyInfo)
    el.financedValue = getFinancedValue(el, state)
  })
  const quoteInfo = JSON.stringify(proposal)
  return {
    partyInfo,
    assetInfo,
    accessoryProductInfo,
    quoteInfo,
  }
}



// This function builds the request object based on the current route and dynamic keys
export async function buildRequestedData(currentRoute: any, dynamicKeys: Record<string, any>) {
  let requestObject: any;
  // Determine the context module based on the current route
  let result;
  if (currentRoute) {
    if (currentRoute._value.path.includes("/mo/")) {
     
      const data = await buildGeneriqueRequestData(store.state.middleOfficeModule, dynamicKeys)
      result = data
      console.log("res---------------", result)
    }
    else if (currentRoute._value.path.includes("/demand/edit")) {
      let data;
      if (dynamicKeys.operation === "insert") {
        data = await buildGenericInfo(store.state.demandModule.offer?.associatedParties![0].associatedParty)
      }
      else {
         data = await buildGeneriqueRequestData(store.state.demandModule, dynamicKeys)
      }
      
      result = data
      console.log("res---------------",result)
    }
    else if (currentRoute._value.path.includes("/task/detail")) {
     
      const data = await buildGeneriqueRequestData(store.state.taskModule, dynamicKeys)
      result = data
      console.log("res---------------", result)
    }

    else if (currentRoute._value.path.includes("/third/edit")) {
      const data = await buildPartyControls(store.state)
      const quoteInfo = JSON.parse(data.data.quoteInfo)
      result = {
        ...data.data,
        quoteInfo: quoteInfo
      }
      console.log("res---------------", result)
    }
    requestObject = {
      ...result,
      ...dynamicKeys, // Spread the dynamic keys into the object
    };
    console.log("requestObject : ", requestObject);
  }
  return requestObject;
}

export function updateMandatoryFieldsElements(elem: any, mandatoryFields: any) {
  const element = document.getElementById(elem.componentId);
  element?.classList.add('tw-evaluated-mandatory-error')
  store.dispatch('controlsModule/setBlockedMandatoryFields', mandatoryFields);
  store.state.controlsModule.blockedMandatoryFields.push(elem)
  // mandatoryFields.push(elem)
}
// This function check the status of mandatory Fields before any Update event
export function ValidateMandatoryFields() {

  const state = store.state.controlsModule.UiStatus;
  let mandatoryFields:any = []
  // const UiStatus = store.state.controlsModule.UiStatus.filter((item:any) => )
  const UiStatus = state ? state.filter((item: any) => item.mandatoryFlag === true) : []

  UiStatus.forEach((elem: any) => {
    const element = document.getElementById(elem.componentId);
    if (element && element!.getElementsByTagName('input').length > 0) {
      const inputType = element!.getElementsByTagName('input')[0].getAttribute('type')
      if (inputType) {
        switch (inputType) {
          case "text":
            element!.getElementsByTagName('input')[0].value === '' ?
              updateMandatoryFieldsElements(elem, mandatoryFields) : (element?.classList.remove('tw-evaluated-mandatory-error'), store.state.controlsModule.blockedMandatoryFields.splice(store.state.controlsModule.blockedMandatoryFields.indexOf(elem),1));
          break;
          case "checkbox":
            element!.getElementsByClassName('.q-toggle')[0].getAttribute('aria-checked') === "false" ?
              updateMandatoryFieldsElements(elem, mandatoryFields) : (element?.classList.remove('tw-evaluated-mandatory-error'), store.state.controlsModule.blockedMandatoryFields.splice(store.state.controlsModule.blockedMandatoryFields.indexOf(elem), 1));
            break;
        
          default:
            break;
        }
      }
      else {
        const roleType = element!.getElementsByTagName('input')[0].getAttribute('role')
        if (roleType) {
          element!.querySelector('.q-field__native')?.getElementsByTagName('span')[0].innerText === '' ?
            updateMandatoryFieldsElements(elem, mandatoryFields) : (element?.classList.remove('tw-evaluated-mandatory-error'), store.state.controlsModule.blockedMandatoryFields.splice(store.state.controlsModule.blockedMandatoryFields.indexOf(elem), 1));
        }
      }
    }
  });
  // store.dispatch('controlsModule/setBlockedMandatoryFields', mandatoryFields);
  return store.state.controlsModule.blockedMandatoryFields.length !== 0 ? false : true
}
export const addedBySystem = ["System","Systéme"];



// This function adds classes to HTML components based on provided flags
export function addClassesToComponent(componentList: any): void {
  componentList.forEach((component: any) => {
    const element: HTMLElement | null = document.getElementById(component.componentId);

    if (element) {
      // Add classes based on component flags
      if (component.disabledFlag) {
        element.classList.add('tw-evaluated-disabled');
      }
      if (component.hiddenFlag) {
        element.classList.add('tw-evaluated-hidden');
      }
      if (component.mandatoryFlag) {
        element.classList.add('tw-evaluated-mandatory');
      }
    } else {
      console.error(`Element with ID ${component.componentId} not found.`);
    }
  });
}

// This function evaluates UI controls based on screen ID, operation, and router
export function evaluateUiControls(screenId: string, operation: string, router: Router, callAPI: boolean) {
  // Dispatch an action to evaluate UI controls
  store.dispatch('controlsModule/evaluateUiControls', {
    screenId: screenId,
    operation: operation,
    router: router,
    callAPI: callAPI,
    callback: (response: any) => {


      // Add classes to components based on the response
      addClassesToComponent(response);
      // addClassesToComponent(response);
      if (store.state.controlsModule.blockedMandatoryFields.length !== 0) {
        ValidateMandatoryFields()
      }
    }
  });
  if (callAPI)
  console.log("UI Evaluation CREATED in : ",screenId);
else console.log("UI Evaluation  UPDATED in :",screenId)

}

export function handleUpdateEvent(screenId : string,operation:string,router: any) {
  evaluateUiControls(screenId, operation, router, false);
}

export function validateContents() {

  const tabs = document.getElementsByClassName('q-tab')
  Array.from(tabs).forEach((tab: any) => tab.click());
  (tabs[0] as HTMLElement).click()
}

//Send Document

export async function SendAllDocuments() {
  const currentRoute = router.currentRoute.value.name;
  const templates = store.state.printDocumentModule.templates
  const selectedTemplates = (templates || []).filter((item) => item.selected)
  await store.dispatch("printDocumentModule/sendTemplate", {
    template: selectedTemplates,
  })
}

// DownloadFiles
export function DownloadAllDocuments() {
  const currentRoute = router.currentRoute.value.name;
  const templates = store.state.printDocumentModule.templates
  const selectedTemplates = (templates || []).filter((item) => item.selected)
  store.dispatch('printDocumentModule/generateDocument', {
    currentRoute: currentRoute,
    templatesSelected: selectedTemplates,
    callback: downloadFile
  })
}

export function downloadFile() {
 const fileId = store.state.printDocumentModule.fileDetailFileId
  store.dispatch("printDocumentModule/downloadFile", {
    request: {
      resourceUid:fileId
    },
    callback: downloadDocumentResponse,
  });
}

export function downloadDocumentResponse(response: any) {
  if (response) {
    const { content } = response;
    const linkSource = `data:${response.format};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = response.originalFileName;
    downloadLink.click();
    store.dispatch('taskModule/setShowLoadingDownload',false)
  }
}

/**
 * Checks if the current user has all of the specified roles.
 * @param {Array} roles - An array of role names to check.
 * @returns {boolean} - Returns true if the user has all roles, otherwise false.
 */
export function checkUserByRole(roles: any) {
  return roles.every((role: any) => store.state.authModule?.user.authorities?.some((authority: any) => authority.authority === role));
}


// Sorts an array of objects by their label property, handling both numeric and string labels.
export const sortItemsByLabel = (items: any[]) => {
  return items.sort((a, b) => {
    const labelA = a.label;
    const labelB = b.label;

    const isNumericA = !isNaN(Number(labelA));
    const isNumericB = !isNaN(Number(labelB));

    if (isNumericA && isNumericB) {
      return Number(labelA) - Number(labelB);
    } else if (isNumericA) {
      return -1;
    } else if (isNumericB) {
      return 1;
    } else {
      return labelA.localeCompare(labelB);
    }
  });
};

export function addMonth(date: any, plusMonth: number) {
  return new Date(date.setMonth(date.getMonth() + plusMonth));
}


/**
 * Determines the service name based on calculation status, offer data, and API type.
 * Translates the service name using i18n or returns a fallback reference.
 * 
 * @param {any} calculatestatus - The status of the calculation ('true' or 'false').
 * @param {any} item - The item object containing service details.
 * @param {Offer} [offer] - Optional offer object containing business data.
 * @returns {string} The translated service name or a fallback reference.
 */
export const serviceName = (calculatestatus: any, item: any, offer?: Offer) => {
  const reference = calculatestatus === 'false' && (offer?.businessData && offer?.businessData.API === businessDataUtils.offerApiSource)
    ? (item.label || item.accessoryProduct.resourceUid)
    : item.reference;

  return i18n.global.te(`demand.product.services.names.${reference?.split('.').join('_')}`)
    ? i18n.global.t(`demand.product.services.names.${reference?.split('.').join('_')}`)
    : reference?.split('.').join('_');
};

/**
 * Calculates the amount before tax (HT) based on calculation status, offer type, and payment options.
 * Formats the amount based on the specified parameters.
 * 
 * @param {boolean} withGetAmount - Whether to return a formatted amount.
 * @param {any} calculatestatus - The status of the calculation ('true' or 'false').
 * @param {any} paymentDatesOption - The payment option (e.g., "INSTALLMENT").
 * @param {any} data - The data object containing amount information.
 * @param {Offer} [offer] - Optional offer object containing business data.
 * @returns {string} The formatted HT amount.
 */
export const amountHT = (withGetAmount: boolean, calculatestatus: any, paymentDatesOption: any, data: any, offer?: Offer) => {
  const amount = calculatestatus === 'false' && (offer?.businessData && offer?.businessData.API === businessDataUtils.offerApiSource)
    ? data.amountWoTax.amount
    : data.amountValueWoTax;

  return withGetAmount
    ? `${getAmountHT(amount)} ${paymentDatesOption.toUpperCase() === paymentDatesOptions.INSTALLMENT ? "/Mois" : "HT"}`
    : `${formatAmoutswitchCurrency("EUR", amount)} ${paymentDatesOption === paymentDatesOptions.INSTALLMENT ? "/Mois" : "HT"}`;
};

/**
 * Calculates the total amount including all taxes (TTC).
 * Formats the amount based on the specified parameters.
 * 
 * @param {boolean} withGetAmount - Whether to return a formatted amount.
 * @param {any} calculatestatus - The status of the calculation ('true' or 'false').
 * @param {any} paymentDatesOption - The payment option (e.g., "INSTALLMENT").
 * @param {any} data - The data object containing amount information.
 * @param {Offer} [offer] - Optional offer object containing business data.
 * @returns {string} The formatted TTC amount.
 */
export const amountTTC = (withGetAmount: boolean, calculatestatus: any, paymentDatesOption: any, data: any, offer?: Offer) => {
  const amount = calculatestatus === 'false' && (offer?.businessData && offer?.businessData.API === businessDataUtils.offerApiSource)
    ? data.amountWTax.amount
    : data.amountValueWTax;

  return withGetAmount
    ? `${getAmountTTC(data, amount)} ${paymentDatesOption === paymentDatesOptions.INSTALLMENT ? "/Mois" : "TTC"}`
    : `${formatAmoutswitchCurrency("EUR", amount)} ${paymentDatesOption === paymentDatesOptions.INSTALLMENT ? "/Mois" : "TTC"}`;
};

/**
 * Determines the appropriate payment date option based on calculation status and offer type.
 * 
 * @param {any} calculatestatus - The status of the calculation ('true' or 'false').
 * @param {any} item - The item object containing payment option details.
 * @param {Offer} [offer] - Optional offer object containing business data.
 * @returns {string} The appropriate payment date option.
 */
export const paymentDatesOption = (calculatestatus: any, item: any, offer?: Offer) => {
  return calculatestatus === 'false' && (offer?.businessData && offer?.businessData.API === businessDataUtils.offerApiSource)
    ? item.proposalAccessoryPaymentDatesOption.resourceUid
    : item.paymentDatesOption;
};

/**
 * Formats the pre-tax amount (HT) based on the given value.
 * 
 * @param {any} amountValueWoTax - The amount before tax.
 * @returns {string} The formatted HT amount or "-" if the value is undefined.
 */
export const getAmountHT = (amountValueWoTax: any) => {
  return amountValueWoTax
    ? `${formatAmoutswitchCurrency("EUR", amountValueWoTax)}`
    : "-";
};

/**
 * Calculates and formats the total amount including all taxes (TTC) based on the given service and amount.
 * 
 * @param {any} service - The service object containing tax rate information.
 * @param {any} amountValueWTax - The amount including tax.
 * @returns {string} The formatted TTC amount or "-" if the value is undefined.
 */
export const getAmountTTC = (service: any, amountValueWTax: any) => {
  const tax: number = service.rate ? Number(service.rate) : 0;
  if (amountValueWTax) {
    const value = amountValueWTax * (1 + tax);
    const result = `${formatAmoutswitchCurrency("EUR", value)}`;
    return result;
  } else {
    return "-";
  }
};
