import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eba65a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "menuHome"
}
const _hoisted_2 = {
  key: 1,
  id: "menu_offer"
}
const _hoisted_3 = {
  key: 2,
  id: "menu_order"
}
const _hoisted_4 = {
  key: 3,
  id: "menu_third"
}
const _hoisted_5 = {
  key: 4,
  id: "menu_tasks"
}
const _hoisted_6 = {
  key: 5,
  id: "menu_settings"
}
const _hoisted_7 = {
  key: 0,
  class: "tw-side-bar-mernu-expanded-items"
}
const _hoisted_8 = {
  key: 6,
  id: "menu_admin"
}
const _hoisted_9 = {
  key: 0,
  class: "tw-side-bar-mernu-expanded-items"
}
const _hoisted_10 = { id: "deliverySchedule" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, { class: "q-scrollarea__container scroll relative-position" }, {
    default: _withCtx(() => [
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_HOME]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'home' ? 'classHover' : '']),
                clickable: "",
                to: "/home",
                onMousemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeColor(true, 'home'))),
                onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeColor(false, 'home'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "home",
                    color: _ctx.module === 'home'
            ? _ctx.colorSecundary
            : _ctx.changeHome
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'home'
            ? _ctx.classSecundary
            : _ctx.changeHome
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_HOME)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_OFFER]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'demand' ? 'classHover' : '']),
                clickable: "",
                to: "/demand/list",
                onMousemove: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeColor(true, 'demand'))),
                onMouseout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeColor(false, 'demand'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "assignment",
                    color: _ctx.module === 'demand'
            ? _ctx.colorSecundary
            : _ctx.changeDemand
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'demand'
            ? _ctx.classSecundary
            : _ctx.changeDemand
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_OFFER)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_ORDER]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'dossier' ? 'classHover' : '']),
                clickable: "",
                to: "/order",
                onMousemove: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeColor(true, 'dossier'))),
                onMouseout: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeColor(false, 'dossier'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "store",
                    color: _ctx.module === 'dossier'
            ? _ctx.colorSecundary
            : _ctx.changeDossier
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'dossier'
            ? _ctx.classSecundary
            : _ctx.changeDossier
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_ORDER)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_THIRD]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                "data-testid": "third_sideBar",
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'third' ? 'classHover' : '']),
                clickable: "",
                to: "/third/list",
                onMousemove: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeColor(true, 'third'))),
                onMouseout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeColor(false, 'third'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "group",
                    color: _ctx.module === 'third'
            ? _ctx.colorSecundary
            : _ctx.changeThird
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'third'
            ? _ctx.classSecundary
            : _ctx.changeThird
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_THIRD)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_TASKS]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'task' ? 'classHover' : '']),
                "data-testid": "action-menu-item",
                clickable: "",
                to: "/task/list",
                onMousemove: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeColor(true, 'task'))),
                onMouseout: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeColor(false, 'task'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "task",
                    color: _ctx.module === 'task'
            ? _ctx.colorSecundary
            : _ctx.changeTask
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'task'
            ? _ctx.classSecundary
            : _ctx.changeTask
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_TASKS)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_SETTINGS]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: "btn tw-sidebar-btn items-center",
                clickable: "",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openSection()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "settings",
                    color: _ctx.module === 'scale'
            ? _ctx.colorSecundary
            : _ctx.changeScale
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'scale'
            ? _ctx.classSecundary
            : _ctx.changeScale
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_SETTINGS)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              (_ctx.openConfiguration)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_AVAILABILITY]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 0,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_availability",
                          clickable: "",
                          to: "/availability/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'availability'
                ? _ctx.classSecundary
                : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_AVAILABILITY)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_SCALE]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 1,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_scale",
                          clickable: "",
                          to: "/scale/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'scale'
                ? _ctx.classSecundary
                : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_SCALE)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_GOOD]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 2,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_good",
                          clickable: "",
                          to: "/good/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'asset'
                ? _ctx.classSecundary
                : _ctx.changeAsset
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_GOOD)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_GARANTY]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 3,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_garanty",
                          clickable: "",
                          to: "/garanty/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'garanty'
                ? _ctx.classSecundary
                : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_GARANTY)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_ENTITYCONFIGURATION]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 4,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_screnConfiguration",
                          clickable: "",
                          to: "/screnConfiguration/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'screnConfiguration'
      ? _ctx.classSecundary
                : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_ENTITYCONFIGURATION)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasAdminOrSuperAdminRole(_ctx.connectedUserAuthorities) || _ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_ADMIN]))
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_item, {
                class: "btn tw-sidebar-btn items-center",
                clickable: "",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openUserAdminSection()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "manage_accounts",
                    color: _ctx.module === 'scale'
            ? _ctx.colorSecundary
            : _ctx.changeScale
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'scale'
            ? _ctx.classSecundary
            : _ctx.changeScale
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_ADMIN)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              (_ctx.openUserAdmin)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_USERS]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 0,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_users",
                          clickable: "",
                          to: "/userAdmin/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'userAdmin'
                ? _ctx.classSecundary
                : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_USERS)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_SCREEN_AUTHORITY]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 1,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_screen_authority",
                          clickable: "",
                          to: "/role-menu",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'userAdmin' ? _ctx.classSecundary : _ctx.changeScale
                ? _ctx.classPrimary
                : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_SCREEN_AUTHORITY)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_SUPERSET]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 2,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_superset",
                          clickable: "",
                          to: "/superset/list",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'userAdmin' ? _ctx.classSecundary : _ctx.changeScale
  ? _ctx.classPrimary
                : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_SUPERSET)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_DASHBOARD]))
                      ? (_openBlock(), _createBlock(_component_q_item, {
                          key: 3,
                          class: "btn tw-sidebar-btn items-center",
                          id: "menu_screen_dashboard",
                          clickable: "",
                          to: "/dashboard",
                          onClick: _ctx.hide
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                class: _normalizeClass(_ctx.module === 'userAdmin' ? _ctx.classSecundary : _ctx.changeScale
                  ? _ctx.classPrimary
                  : _ctx.classSecundary
                )
                              }, _toDisplayString(_ctx.$t("main.menu." + _ctx.MenuRoles.ROLE_MENU_DASHBOARD)), 3)
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", null, [
          (_ctx.checkUserByRole([_ctx.MenuRoles.ROLE_MENU_PLANNIG]))
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 0,
                class: _normalizeClass(["btn tw-sidebar-btn items-center", _ctx.module === 'deliverySchedule' ? 'classHover' : '']),
                clickable: "",
                to: "/deliverySchedule",
                onMousemove: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeColor(true, 'deliverySchedule'))),
                onMouseout: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changeColor(false, 'deliverySchedule'))),
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: "store",
                    color: _ctx.module === 'deliverySchedule'
            ? _ctx.colorSecundary
            : _ctx.changeDeliverySchedule
              ? _ctx.colorPrimary
              : _ctx.colorSecundary
            
                  }, null, 8, ["color"]),
                  _createVNode(_component_q_item_label, {
                    class: _normalizeClass(_ctx.module === 'deliverySchedule'
            ? _ctx.classSecundary
            : _ctx.changeDeliverySchedule
              ? _ctx.classPrimary
              : _ctx.classSecundary
            )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("main.menu.deliverySchedule")), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}