import {
  fetchSupersetConfiguration,
  saveSuperset,
  updateSuperset,
} from "@/store/services/superset/supersetService";
import { SuperSetState } from "@/types";
import { Module } from "vuex";

const supersetModule: Module<SuperSetState, any> = {
  namespaced: true,
  state: {
    supersets: [],
    existingSupersets: [],
  },
  getters: {
    getSupersets(state) {
      return state.supersets ?? [];
    },
    getExistingSupersets(state) {
      return state.existingSupersets ?? [];
    },
  },
  actions: {
    addNewSuperSetItem({ commit }) {
      commit("addNewSuperSetItem");
    },
    fetchAllSuperSets({ commit }) {
      fetchSupersetConfiguration().then((data: any) => commit("initSupersets", data));
    },
    async saveSupersets({ commit, getters }) {
      const { getSupersets, getExistingSupersets } = getters;
      await getSupersets.forEach(async (superset: any) => {
        if (!superset.id) {
          const id = await saveSuperset(superset);
          superset.id = id;
        } else {
          const existingSuperset = getExistingSupersets.find(
            (s: any) =>
              s.id === superset.id || s.param_key === superset.param_key
          );
          if (
            existingSuperset &&
            (existingSuperset.param_key !== superset.param_key ||
              existingSuperset.param_value !== superset.param_value)
          ) {
            await updateSuperset(superset);
          }
        }
      });
      commit("initSupersets", getSupersets); // Reinitialize the lists after the save.
    },
    setSupersets({ commit }, payload) {
      commit("setSupersets", payload);
    },
    updateParamKey({ commit }, payload) {
      commit("updateParamKey", payload);
    },
    updateParamValue({ commit }, payload) {
      commit("updateParamValue", payload);
    },
    removeNewKeyValue({ commit }, payload) {
      commit("removeNewKeyValue", payload);
    },
  },
  mutations: {
    initSupersets(state, payload) {
      state.supersets = payload ?? [];
      state.existingSupersets =
        payload?.map((s: any) => ({
          id: s.id,
          param_key: s.param_key,
          param_value: s.param_value,
        })) ?? [];
    },
    setSupersets(state, payload) {
      state.supersets = payload ?? [];
    },
    addNewSuperSetItem(state) {
      const superset = {
        id: null,
        param_key: "SUPERSET_",
        param_value: "",
      };
      state.supersets = state.supersets
        ? [superset, ...state.supersets]
        : [superset];
    },
    updateParamKey(state, { index, newValue }) {
      if (state.supersets) state.supersets[index].param_key = newValue;
    },
    updateParamValue(state, { index, newValue }) {
      if (state.supersets) state.supersets[index].param_value = newValue;
    },
    removeNewKeyValue(state, payload) {
      if (state.supersets) state.supersets.splice(payload, 1);
    },
  },
};
export default supersetModule;
